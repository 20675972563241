import React from 'react';

import styled from '@emotion/styled';
import { useRouter } from 'next/router';

import { Agent } from '@agentero/models';
import { Role } from '@agentero/models/agent';

import { TierStatusLabel } from 'modules/shared/tier/TierStatusLabel';
import {
	getSettingsAdminDetailsRoutePath,
	getSettingsAgentDetailsRoutePath,
	settingsTiersAndBillingRoute
} from 'routes';

const NavAvatarContainer = styled('button')<{ clickable: boolean }>`
	display: flex;
	width: calc(100% + 32px);
	max-width: initial;
	align-items: center;
	padding: 8px 16px;
	margin: 0 -16px;
	color: ${({ theme }) => theme.colors.gray.lighten20};
	text-align: left;
	border-width: 0;
	height: auto;
	cursor: pointer;
	transition: 0.15s;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			color: ${({ theme }) => theme.colors.gray.lighten20};
			background: ${({ theme }) => theme.colors.gray.lighten90};
		}

		&:focus {
			color: ${({ theme }) => theme.colors.gray.lighten20};
		}
	}

	${({ clickable }) =>
		clickable &&
		`
		&:active {
			color: ${({ theme }) => theme.colors.gray.base};
			background: ${({ theme }) => theme.colors.gray.lighten80};
		}`}
`;

const NavAvatarFig = styled.figure`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	margin-right: 8px;
	border-radius: 50%;
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.gray.lighten90};
	overflow: hidden;
`;

const NavAvatarImg = styled.div`
	width: 40px;
	height: 40px;
	background-size: cover;
	background-position: center center;
`;

const NavAvatarLetter = styled.div`
	${({ theme }) => theme.textSettings.L}
	${({ theme }) => theme.fontWeight.regular}
	color: ${({ theme }) => theme.colors.primary.base};
`;

const NavAvatarInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 0.25rem;
	flex: 1;
`;

const NavAvatarName = styled.div`
	${({ theme }) => theme.textSettings.XS};
	${({ theme }) => theme.fontWeight.regular}
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
`;

const NavAvatarToggle = styled.div<{ isOpen: boolean }>`
	position: relative;
	width: 24px;
	height: 24px;

	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 8px;
		height: 8px;
		margin: -5px 0 0 -4px;
		border-left: 2px solid ${({ theme }) => theme.colors.gray.lighten20};
		border-bottom: 2px solid ${({ theme }) => theme.colors.gray.lighten20};
		transform: rotate(-45deg);
		box-sizing: border-box;
	}

	${({ isOpen }) =>
		isOpen &&
		`
		&:before {
			margin: -3px 0 0 -4px;
			transform: rotate(135deg);
		}`}
`;

type NavAvatarProps = {
	agent: Agent;
	isOpen?: boolean;
	toggleHeaderMenu?: () => void;
};

export const NavAvatar: React.FC<NavAvatarProps> = ({ agent, isOpen, toggleHeaderMenu }) => {
	const { photoUrl, firstName, lastName, role, id, subscription } = agent;
	const { push } = useRouter();

	return (
		<NavAvatarContainer
			clickable={toggleHeaderMenu ? true : false}
			onClick={
				toggleHeaderMenu
					? toggleHeaderMenu
					: () =>
							push(
								role !== Role.SuperAdmin
									? getSettingsAgentDetailsRoutePath(id)
									: getSettingsAdminDetailsRoutePath(id)
							)
			}>
			<NavAvatarFig>
				{photoUrl ? (
					<NavAvatarImg style={{ backgroundImage: `url(${photoUrl})` }} />
				) : (
					<NavAvatarLetter>{firstName.charAt(0)}</NavAvatarLetter>
				)}
			</NavAvatarFig>
			<NavAvatarInfo>
				<NavAvatarName>
					{firstName} {lastName}
				</NavAvatarName>
				{subscription && (
					<TierStatusLabel
						isPaymentFailed={subscription.isPaymentFailed}
						tier={subscription.tier}
						onClick={() => push(settingsTiersAndBillingRoute)}
					/>
				)}
			</NavAvatarInfo>
			{toggleHeaderMenu && <NavAvatarToggle isOpen={isOpen ?? false} />}
		</NavAvatarContainer>
	);
};
