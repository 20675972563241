import React from 'react';

import styled from '@emotion/styled';

import { IconClose } from '@agentero/icons';

import { useNavStore } from 'packages/contexts/useNavStore';

const NavCloseButtonContainer = styled('button')`
	position: fixed;
	top: 18px;
	right: 18px;
	cursor: pointer;

	${({ theme }) => theme.mediaqueries.L} {
		display: none;
	}

	svg {
		width: 24px;
		height: 24px;
	}
`;

export const NavCloseButton = () => {
	const [, setIsOpen] = useNavStore();

	return (
		<NavCloseButtonContainer onClick={() => setIsOpen(false)}>
			<IconClose />
		</NavCloseButtonContainer>
	);
};
