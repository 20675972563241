import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styled from '@emotion/styled';

export const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const Container = (
	props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
) => <StyledContainer {...props} data-role="container" />;
