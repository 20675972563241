import { PropsWithChildren } from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { CheckList, CheckListItem, Grid, GridItem, Text } from '@agentero/components';
import { IconCheckCircle, LogoAgentero } from '@agentero/icons';

import { AuthBg } from './auth/AuthBg';
import { AuthFeatures } from './auth/AuthFeatures';
import { AuthLogoAgentero } from './auth/AuthLogoAgentero';
import { AuthMain } from './auth/AuthMain';

type AuthProps = {
	hasFeatures?: boolean;
	hasFeaturesTitle?: boolean;
};

const AuthContainer = styled.div`
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	padding: 16px;

	${({ theme }) => theme.mediaqueries.S} {
		padding: 24px;
	}

	${({ theme }) => theme.mediaqueries.M} {
		padding: 48px;
	}
`;

export const Auth = ({
	children,
	hasFeatures = false,
	hasFeaturesTitle = true
}: PropsWithChildren<AuthProps>) => {
	const { t } = useTranslation('auth');

	return (
		<>
			<AuthBg />
			<AuthContainer>
				<AuthMain>
					<Grid spacing={16} css={{ flex: 1, minHeight: 0 }}>
						<GridItem xs={12} lg={5}>
							<AuthFeatures>
								<AuthLogoAgentero>
									<LogoAgentero />
								</AuthLogoAgentero>

								{hasFeaturesTitle && (
									<Text
										size={{ xs: 'L', md: 'XL' }}
										mb={hasFeatures ? 40 : 0}
										css={{ opacity: hasFeatures ? 1 : 0.5 }}
										as="h2">
										<b>{t('features.title')}</b>
									</Text>
								)}

								{hasFeatures && (
									<CheckList>
										{Array.from({ length: 3 }).map((_, index) => (
											<CheckListItem key={index} Icon={IconCheckCircle}>
												<Text>
													<b>{t(`features.list.${index}.title`)}</b>
												</Text>
												<Text size="S" mb={index < 2 ? 24 : 0}>
													{t(`features.list.${index}.description`)}
												</Text>
											</CheckListItem>
										))}
									</CheckList>
								)}
							</AuthFeatures>
						</GridItem>

						<GridItem xs={12} lg={7}>
							<AuthLogoAgentero isDesktopHidden>
								<LogoAgentero />
							</AuthLogoAgentero>

							{children}
						</GridItem>
					</Grid>
				</AuthMain>
			</AuthContainer>
		</>
	);
};
