import { useCallback, useEffect } from 'react';

import styled from '@emotion/styled';
import { useRouter } from 'next/router';

import { useNavStore } from 'packages/contexts/useNavStore';
import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';

import { AggregatorNavList } from './nav/AggregatorNavList';
import { NavAvatar } from './nav/NavAvatar';
import { NavBody } from './nav/NavBody';
import { NavCloseButton } from './nav/NavCloseButton';
import { NavGetQuotes } from './nav/NavGetQuotes';
import { NavHeader } from './nav/NavHeader';
import { NavLogo } from './nav/NavLogo';
import { NavSettings } from './nav/NavSettings';
import { SuperAdminNavList } from './nav/SuperAdminNavList';

const NavContainer = styled.nav<{ isOpen: boolean }>`
	position: fixed;
	/* TODO: fix z-index */
	z-index: 1005;
	top: 0;
	left: -100%;
	bottom: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 0;
	background: ${({ theme }) => theme.colors.white};
	transform: ${({ isOpen }) => (isOpen ? 'translateX(100%)' : 'translateX(0)')};
	transition: ${({ isOpen }) =>
		isOpen
			? 'transform 0.6s cubic-bezier(0.22, 1, 0.36, 1)'
			: 'transform 0.4s cubic-bezier(0.64, 0, 0.78, 0)'};
	transition-delay: ${({ isOpen }) => (isOpen ? '0.025s' : '0s')};
	will-change: auto;
	pointer-events: auto;

	+ [data-role='container'] {
		transform: ${({ isOpen }) => (isOpen ? 'translateX(104px)' : 'translateX(0)')};
		transition: transform 0.6s cubic-bezier(0.22, 1, 0.36, 1);
		transition-delay: ${({ isOpen }) => (isOpen ? '0s' : '0.3s')};

		${({ theme }) => theme.mediaqueries.L} {
			margin-left: 192px;
			transform: unset;
		}
	}

	${({ theme }) => theme.mediaqueries.L} {
		left: 0;
		width: 192px;
		transform: inherit;
		transition: 0s;
		border-right: 1px solid ${({ theme }) => theme.colors.gray.lighten90};
	}
`;

const NavWrapper = styled.div`
	width: 100%;
	max-width: 160px;
	margin: 0 auto;
`;

const NavFooter = styled.footer`
	display: flex;
	align-items: center;
	width: 100%;
	position: sticky;
	bottom: 0;
	left: 0;
	transform: translateX(0);
	margin-bottom: 152px;
	background-color: ${({ theme }) => theme.colors.white};
`;

export const Nav = () => {
	const [isOpen, setIsOpen] = useNavStore();
	const { data: agent } = useAgentResource();

	const { events } = useRouter();

	const closeNavIfMobile = useCallback(() => {
		if (isOpen) setIsOpen(false);
	}, [isOpen, setIsOpen]);

	useEffect(() => {
		events.on('routeChangeStart', closeNavIfMobile);
	}, [isOpen, events, closeNavIfMobile]);

	return (
		<NavContainer isOpen={isOpen}>
			<NavHeader>
				<NavWrapper>
					<NavLogo />
					<NavAvatar agent={agent} />
				</NavWrapper>
			</NavHeader>

			<NavGetQuotes />

			<NavBody>
				<NavWrapper>
					<AggregatorNavList />
					<SuperAdminNavList />
				</NavWrapper>
			</NavBody>

			<NavCloseButton />
			<NavFooter>
				<NavWrapper>
					<NavSettings />
				</NavWrapper>
			</NavFooter>
		</NavContainer>
	);
};
