import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styled from '@emotion/styled';

export const StyledMain = styled.main`
	flex: 1;
	display: flex;
	flex-direction: column;
	min-height: 0;
`;

export const Main = (props: DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>) => (
	<StyledMain {...props} role="main" />
);
